<template>
  <v-container grid-list-xs class="home-container">
    <Fields
      group-id="Home"
      :fields="readFields"
      :filters="searchFields"
      :readonly="true"
    />
  </v-container>
</template>

<script>
export default {
  name: 'home',
  data: () => ({
  }),
  computed: {
    options() {
      let { options } = this.$store.state.settings.currentPage;
      if (!Array.isArray(options)) {
        options = [options];
      }
      if (options.length > 0) {
        options = [...options];
        // TODO update to use loop with fields in template
        return options.pop().fields;
      }
      return [];
    },
    readFields() {
      return (this.options && this.options.read) ? this.options.read : [];
    },
    searchFields() {
      return (this.options && this.options.search)
        ? this.options.search
        : null;
    },
  },
};
</script>

<style lang="scss">
  .home-container {
    &,
    // For only one field force to use full-height
    & > .layout > .flex > .v-layout-field:only-child {
      height: calc(100vh - 56px);
      @media (min-width: 960px) {
        height: calc(100vh - 64px);
      }
    }
    // Prevent useless margin for only one field
    & > .layout > .flex > .v-text-field:only-child {
      padding: 0;
      margin: 0;
    }
  }
</style>
